<template>
  <!-- eslint-disable -->
    <sw-table
      table-id="302b02bde5db48209473323ce6c1774a"
      show-columns
      show-search
      :fields.async="fields"
      :pagination="pagination"
      @change-pagination="Object.assign(pagination, $event)"
      @change-search-query="search = $event"
      @reload-content="loadData"
      @set-fields="fields = $event"
      class="position-relative overflow-hidden"
    >
      <template #table>
        <!--      Table      -->
        <b-table
          :items="items"
          class="offer-table"
          :fields="fields.filter(e => e.visible)"
          :sort-by.sync="sorter.sortBy"
          :no-local-sorting="true"
          :sort-desc.sync="sorter.sortDesc"
          striped
          responsive
          :busy="isLoading"
          show-empty
          @sort-changed="Object.assign(sorter, $event); loadData()"
        >
          <!--    Head    -->
          <template #head()="{ label }">
            {{ $t(`${label}`) }}
          </template>

          <template #head(select)="{ label }">
              <b-form-checkbox v-model="selectAll"
                               @change="selectAllCompanies"
              />
          </template>
          <template #cell(select)="{ item }">
              <b-form-checkbox v-model="selectedItems" :value="item.id" />
          </template>

          <template #cell(id)="{ index }">
            #{{ getOrderNo(index, pagination, sorter) }}
          </template>

          <!--    Table Name    -->
          <template #cell(name)="{ item }">
            <span
              class="text-primary cursor-pointer"
              @click="$router.push({ name: 'organizationsPreview', params: { 'id': item.id } })"
              v-b-tooltip
              :title="item.name"
            >
              {{ item.name | truncate(50) }}
            </span>
          </template>

          <!--    Vat payer    -->
          <template #cell(vat)="{ item, value }">
            <span v-if="item.vatPayer">
              {{ value }}%
            </span>
            <span v-else>
              {{ $t('Customizer.Animations.None') }}
            </span>
          </template>

          <template #cell(address)="{ item }">
            <div class="text-nowrap">
              {{ getAddress(item) }}
            </div>
          </template>
          <template #cell(addressCorrespondence)="{ item }">
            <div class="text-nowrap">
              {{ getAddress(item, 'correspondence') }}
            </div>
          </template>

          <template #cell(action)="{ item }">
              <b-dropdown
                      id="dropdown-dropleft"
                      dropleft
                      no-caret
                      variant="flat"
              >
                  <template #button-content>
                      <feather-icon icon="MoreVerticalIcon" />
                  </template>

                  <b-dropdown-item @click="$router.push({ name: 'organizationsEdit', params: { 'id': item.id } })">
                      <feather-icon
                              class="mr-25"
                              icon="EditIcon"
                      />
                      {{ $t('Edit') }}
                  </b-dropdown-item>

                  <b-dropdown-item v-if="checkRequiredPermissions([$roles.ORGANIZATION_DELETE])" @click="$emit('delete-item', item.id)">
                      <feather-icon
                              class="mr-25"
                              icon="TrashIcon"
                      />
                      {{ $t('Delete') }}
                  </b-dropdown-item>
              </b-dropdown>
          </template>

          <!-- Other -->
            <!--    Other    -->
            <template #cell()="row">
                <table-default-cell
                        :field="row.field"
                        :value="row.value"
                />
            </template>

            <!--      Empty      -->
            <template #empty>
                <empty-content />
            </template>

            <!--      Table Busy      -->
            <template #table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner
                            class="align-middle"
                            variant="primary"
                    />
                </div>
            </template>
        </b-table>
      </template>
    </sw-table>
</template>

<script>
/* eslint-disable */

import { BTable, VBTooltip } from 'bootstrap-vue'
import { GET_ORGANIZATIONS_ALL } from '@/@constants/mutations'
import { formatDate } from '@core/utils/filter'

export default {
  components: {
    BTable,
  },
  emits: ['edit'],
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    filters: {
      required: false,
      default: null,
    },
  },
  data: () => ({
    isLoading: false,
    sorter: {
      sortBy: 'id',
      sortDesc: true,
    },
    pagination: {
      perPage: 25,
      totalRecords: 0,
      currentPage: 1,
    },

    selectedItems: [],
    selectAll: false,
    search: '',
    fields: [
      {
          key: 'select', label: 'Select', visible: true,
      },
      {
        key: 'id', label: '#', visible: true, sortable: true,
      },
      { key: 'name', label: 'Name', visible: true },
      { key: 'vatId', label: 'Nip', visible: true, tag: 'b-badge', props: { variant: 'light-primary' } },
      { key: 'address', label: 'Address', visible: true },
      { key: 'addressCorrespondence', label: 'AddressForCorrespondence', visible: true, tag: 'b-badge', props: { variant: 'primary' } },
      { key: 'allAssignedContacts', label: 'numberOfRelatedContacts', visible: true, tag: 'b-badge', props: { variant: 'primary' } },
      { key: 'allContactThreads', label: 'totalNumberOfThreads', visible: true },
      { key: 'vatPayer', label: 'VatPayer', visible: true },
      { key: 'vat', label: 'ValueVat', visible: true },
      { key: 'parentOrganization', label: 'ParentOrganization', visible: true },
      { key: 'contacts', label: 'Contacts', visible: true, tag: 'avatar', props: { contactActionPopover: true } },
      { key: 'users', label: 'Users', visible: true },
      { key: 'createdAt', label: 'CreatedTime', visible: true },
    ],
    items: [],
  }),
  watch: {
    selectedItems: {
      handler() {
          this.selectAll = this.selectedItems.length === this.items.length

          this.$emit('select-items', this.selectedItems)
      },
      deep: true,
    },
    filters: {
      deep: true,

      handler() {
        this.loadData()
      },
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    formatDate,
    selectAllCompanies() {
      this.selectedItems = this.selectAll ? this.items.map(company => company.id) : []

      this.$emit('select-items', this.selectedItems)
    },
    loadData() {
      this.isLoading = true

      const {
        filters, pagination, search, sorter,
      } = this

      this.$store.dispatch(`organizations/${GET_ORGANIZATIONS_ALL}`, {
        filters,
        search,
        pagination,
        sorter,
      })
        .then(res => {
          this.items = res.data.items
          this.pagination.totalRecords = res.data.totalItemCount
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
